.mdx-editor {
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
}

.example-5 {
  position: relative;
}

.example-5 svg {
  position: absolute;
}

.example-5 .line {
  stroke-dasharray: 200;
  stroke-width: 5px;
  fill: transparent;
  stroke: rgb(170, 255, 0);
  animation: svgAnimation 2.5s ease forwards;
}
@keyframes svgAnimation {
  0% {
    stroke-dasharray: 200;
    stroke-dashoffset: 800;
    stroke: rgb(170, 255, 0);
  }

  70% {
    stroke-dasharray: 200;
    stroke-dashoffset: 0;
    stroke: rgb(170, 255, 0);
  }

  100% {
    stroke-dasharray: 2000;
    stroke-dashoffset: 0;
    stroke: rgb(255, 255, 255);
    /* White for mobile (default) */
  }
}

@media (min-width: 768px) {
  @keyframes svgAnimation {
    0% {
      stroke-dasharray: 200;
      stroke-dashoffset: 800;
      stroke: #3C23F2
    }

    70% {
      stroke-dasharray: 200;
      stroke-dashoffset: 0;
      stroke: #3C23F2
    }

    100% {
      stroke-dasharray: 2000;
      stroke-dashoffset: 0;
      stroke: #FFF
      /* Black for desktop */
    }
  }
}